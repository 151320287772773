export default {
	translation: {
		commonText: {
			start: '开始游戏',
			language: "cn",
			noData: "暂无数据"
		},
		commonTips: {
			copySuccess: '复制成功~',
			copyFail: '复制失败~',
			saveSuccess: "保存成功~",
			saveFail: "保存失败~",
			keyConflict: "键位冲突~"
		},
		header: {
			story: '游戏剧情',
			mode: '游戏模式',
			nft: 'nft生态',
			intro: '白皮书',
			casting: "铸造",
			connectWallet: "连接钱包",
			logOut: "退出登录"
		},
		titleContent: {
			props: "任何持有TETRIVERSE NFT的玩家都可以参与1V1或者1V8的PVP挑战模式",
			propsLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-wan-fa/dao-ju-mo-shi",
			battle: "经典俄罗斯方块玩法，3人/6人同屏竞技，需要门票才能参与，获得的门票与Token可以相互兑换",
			battleLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-wan-fa/jing-ji-mo-shi-xian-shi-kai-fang",
			classic: "任何持有TETRIVERSE NFT的玩家都可以参与1V1或者1V8的PVP挑战模式",
			classicLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-wan-fa/jing-dian-mo-shi",
			solo: "任何持有TETRIVERSE NFT的玩家，都可以免费玩第一关，在一定时间内（300秒）不死亡视为过关，玩家过关后即可上排行榜获得通关奖励，排名靠前的玩家奖励更丰厚；",
			soloLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-wan-fa/sheng-cun-mo-shi",
			market: "Tetriverse与其他休闲游戏的关键区别之一是，你从游戏中获得的几乎所有资产都属于你。\nTetriverse中的资产几乎意味着你在游戏中获得的一切，方块人、TET、VSYS、门票等等。",
			marketLink: "https://tetriverse-white-paper.gitbook.io/untitled/shi-chang-he-liu-dong-xing-chi-zi/shi-chang",
			ticket: "免费门票；付费门票；奖励门票\n免费门票，每用户可免费获得一张\n付费门票，通过代币购买，可再兑换为TET代币\n奖励门票，游戏获胜后，可赢取其他玩家的门票，可再兑换为TET代币",
			ticketLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-nei-wu-pin/men-piao",
			rank: "完成每个关卡后，玩家将获得丰厚的 TET 代币奖励；\n第一关为免费关卡，通关第一关后可上榜，上榜玩家可平分每日空投奖励。\n第2-10关的用户，可获得自身上阵NFT价值之和94%的奖励TET代币，其余6%的价值，将会进入关卡奖励池",
			rankLink: "https://tetriverse-white-paper.gitbook.io/untitled/you-xi-wan-fa/sheng-cun-mo-shi#:~:text=%E4%B8%AA%E6%A9%99%EF%BC%88SSR%EF%BC%89%EF%BC%89-,%E5%A5%96%E5%8A%B1,-%E5%AE%8C%E6%88%90%E6%AF%8F%E4%B8%AA",
			mint: "方块人是游戏中的核心角色，是一种可以铸造、升级、收集和交易的NFT。他们是一种热爱生活、追求幸福、追求快乐、立秋给别人带来欢乐的平行宇宙生物。他们的内心充满了爱——他们希望是每个人都友好和快乐。每个账户地址都可以不限量的收集五种不同的方块人图片，其稀有度、技能等属性均单独计算。获取方块人即可玩大部分游戏内容并获得奖励。",
			mintLink: "https://tetriverse-white-paper.gitbook.io/untitled/qu-kuai-lian-nft-he-dai-bi/fang-kuai-ren-nfts",
			more: "更多>>"
		},
		home: {
			storyTitle: '游戏剧情',
			modeTitle: '游戏模式',
			nftTitle: 'nft生态',
			earn: '方块宇宙',
			stroyContent: "《TETRIVERSE》是一款轻松解压的俄罗斯方块消除类游戏。\n\n用最简单的方式向你介绍Web3，让你在享受乐趣的同时轻松赚取你的web3收入，NFT与加密货币让TETRIVERSE这款经典游戏的方方面面焕发了新的生命。",
			modeContent: "TETRIVERSE使玩家能够在玩身临其境感受到WEB3游戏的乐趣，低门槛的进入生态系统对来自全球各个角落的各种经验水平和年龄的玩家都具有吸引力。\n\n该模型旨在建立并不断发展一个积极参与、健康且令人兴奋的社区，其中许多可能是首次接触区块链技术的 Web2 游戏玩家。",
			nftContent: "方块人是游戏中的核心角色，是一种可以铸造、升级、收集和交易的NFT。他们是一种热爱生活、追求幸福、追求快乐、立秋给别人带来欢乐的平行宇宙生物。\n\n他们的内心充满了爱——他们希望是每个人都友好和快乐。每个账户地址都可以不限量的收集五种不同的方块人图片，其稀有度、技能等属性均单独计算。\n\n获取方块人即可玩大部分游戏内容并获得奖励。",
			overview: "概念",
			verification: "验证",
			developing: "发展",
			tokens: "代币经济",
			home: "官网",
			community: "社区",
			pawn: "质押",
			airdrop: "NFT  空投",
			event: "赛事",
			marketing: "营销",
			appStore: "应用商店",
			moreEvents: "更多比赛",
			more: "更多",
			socialMediaLinks: "加入我们社区",
			reserved: "2024 俄罗斯方块保留所有权利",
			termsAndConditions: "条款和条件",
			fontContent: "TETRIVERSE使玩家能够在玩身临其境感受到WEB3游戏的乐趣，低门槛的进入生态系统对来自全球各个角落的各种经验水平和年龄的玩家都具有吸引力，该模型旨在建立并不断发展一个积极参与、健康且令人兴奋的社区，其中许多可能是首次接触区块链技术的 Web2 游戏玩家。",
			huobiExchange: "库币交易所",
			huobiActiveTime: "7月1 - 7月7",
			huobiActiveContent: "VSYS交易大赛，瓜分10,000美元奖励",
			moreActive: "享更多权益",
			inviteYourFriends: "邀请好友赢奖励",
			invite: "邀请",
		},
		invite: {
			tetriverse: "方块宇宙",
			invitationEvent: "特别邀请活动",
			copyLink: "复制链接，邀请好友",
			step1: "第一步",
			step1Content: "复制邀请链接并发送给朋友",
			step2: "第二步",
			step2Content: "好友将通过链接注册",
			step3: "第三步",
			step3Content: "获得 TET 硬币和其他奖励",
			invites: "邀请总数",
			validInvites: "有效邀请",
			validInvitesContent: "受邀玩家需在活动期间登录并玩游戏至少3天，方可视为有效邀请",
			currentValidInvitations: "当前有效邀请：",
			inviteContent1: "邀请",
			inviteContent2: "位好友获得",
			currentProgress: "当前进度：",
			collect: "领取",
			collected: "已领取",
			claimSuccessful: "领取成功: ",
			claimReward: "领取奖励",
			nftRewards: "NFT奖励将于活动结束后统一发放，请耐心等待",
		},
		wallet: {
			downloadError: "钱包未下载",
			versionError: "当前版本滞后请更新到最新版本",
			authorizationError: "请授权访问您的账户",
			networkSettingError: "请切换到Mainnet以继续",
			walletLoginError: "钱包未登录",
			fetchingAddress: "获取地址中。。。",
			vsysAddressChange: "钱包地址不匹配",
			connectWallet: "连接钱包",
			pleaseInstallWallet: "进入俄罗斯方块前，请先安装泰坦钱包",
			downloaded: "已下载",
			goto: "立即前往",
			walletSuccess: "链上操作成功",
			recipientAddress: "收款方",
			walletNFTAction: "请在钱包里完成转入NFT",
			walletTokenAction: "请在钱包里完成充值",
			actionOK: "已转入",
			walletActionUpdate: "提现申请已提交，请在钱包里查看记录",
			transferring: "转入中,请等待",
			tokenInsufficientFunds: "Token余额不足",
		},
		user: {
			registerAccount: "注册",
			emailAccount: "邮箱",
			nickname: "昵称（选填）",
			invitationCode: "邀请码（选填）",
			verificationCode: "验证码",
			getVerificationCode: "获取验证码",
			newPassword: "新密码",
			registrationAgreement: "我同意注册协议",
			registrationCompleted: "注册完成",
			haveAccount: "已有帐号登录",
			sendingVerificationCode: "邮箱验证码发送中",
			sendVerificationCodeSuccess: "邮箱验证码已发送",
			sendVerificationCodeFailed: "邮箱验证码发送失败",
			resetingPassword: "密码重置中",
			resetPasswordSuccess: "密码已重置",
			resetPasswordFailed: "密码重置失败",
			registerSuccess: "注册成功",
			registerFailed: "注册失败",
			passwordEmailNull: "密码，验证码或帐号为空",
			emailError: "邮箱格式错误",
			passwordError: "8-15个字符，字母+数字的组合(不包含特殊字符)",
			passwordConfirmError: "两次密码输入不一致",
			nicknameError: "昵称长度不能大于16",
			loginAccount: "登录帐号",
			interEmail: "请输入邮箱帐号",
			interPassword: "请输入帐号密码",
			login: "登录",
			forgotPassword: "忘记密码",
			thirdPartyAccount: "第三方帐号登录",
			register: "注册",
			loginSuccess: "登录成功",
			loginFailed: "登录失败",
			loginFailed1001: "密码或账户错误",
			login2Failed1001: "二级密码错误",
			emailNull: "邮箱号码不能为空",
			previous: "上一步",
			next: "下一步",
			interCode: "请输入验证码",
			sendCode: "验证码已发送到",
			requestNew: "重新获取",
			second: "秒",
			resetPassword: "重新设置密码",
			resetPasswordSubtitle: "8-15个字符，使用数字+字母",
			confirmPassword: "请确认新密码",
			completed: "完成",
		},
		responseCode: {
			// 100*
			invalidArgument: "输入参数有误",
			requestTimeout: "请求超时",
			notFound: "未查找到数据",
			apiError: "请求调用错误",
			wrongAPIKey: "APIKey错误",
			// 200*
			accountAlreadyExist: "该账号已存在",
			accountNotExist: "帐号不存在",
			verificationCodeError: "验证码错误",
			tokenError: "身份认证令牌错误",
			accountAddressNotSet: "账户钱包地址未设置",
			accountSecondPasswordNotSet: "账户未设置二级密码",
			sameNFTAvatarSet: "用户已经设置相同NFT头像",
			emailAlreadyExist: "账户邮箱已存在",
			nicknameAlreadyExist: "账户昵称已存在",
			invitationCodeUsed: "账户邀请码已使用",
			accountBanned: "账户已被封禁",
			inviterNotExist: "邀请码无效",
			// 300*
			balanceNotEnough: "余额不足",
			notTicketOwner: "不是门票所有者",
			ticketInvalid: "门票已失效",
			freeTicketCannotRefund: "免费门票不能提现",
			notNFTOwner: "不是NFT所有者",
			NFTAlreadyOnChain: "NFT已经在链上",
			NFTLocked: "NFT在锁仓期",
			freeTicketAlreadyExists: "用户今日已领过门票",
			userAlreadyHasFreeNFT: "用户已领取免费NFT",
			NFTInMarketplace: "NFT已经市场里",
			NFTNotOnSale: "NFT不在售卖状态",
			cannotBuyOwnNFT: "不能购买自己的NFT",
			NFTNetworkStatusError: "NFT网络状态错误",
			NFTValidStatusError: "NFT已失效",
			NFTMintBalanceError: "铸造NFT失败：转账金额不足，金额正在退回",
			invalidWithdrawFeeItem: "Token手续费减免劵无效",
			wrongSecondPassword: "二级密码错误",
			invitationRewardNotFound: "邀请奖励活动即将到来",
			userInvitationRewardNotFound: "未找到邀请奖励",
			userInvitationRewardFinished: "已领取所有邀请奖励",
			userInvitationRewardInvitationNotEnough: "不满足邀请奖励领取条件",
			NFTInvalid: "已激活的NFT不能提现",
			// 400*
			transactionNotFound: "交易不存在",
			minimalTokenAmountReached: "用户输入Token数量小于阈值",
			// 500*
			userAlreadyHasLockedTicket: "用户已有在游戏中锁定的门票",
			userHasNoValidTicket: "用户没有可用门票",
			userHasNoLockedTicket: "用户没有锁定状态的门票",
			// 1000*
			serverInternalError: "服务器错误",
			vsysChainServicesOnMaintenance: "VSYS链上服务维护中",
			// other
			otherCode: "失败",
			networkFailed: "网络错误",
			success: "成功",
			failed: "失败",
		},
		hall: {
			requiredOneNFT: "至少需要一个NFT",
			joinGame: "加入游戏中...",
			ranking: "排行",
			ticket: "门票",
			minting: "铸造",
			settings: "设置",
			market: "市场",
			connectWallet: "请连接钱包",
			survivalMode: "生存模式",
			itemBattle: "道具战",
			multiplayerCompetition: "多人竞技",
			classicMode: "经典模式",
			myNft: '我的Nft',
			settings: '设置',
			tickets: '门票',
			security: '安全',
			income: '收支',
			dynamic: '动态',
			walletAssets: '钱包资产',
			gameTokens: '游戏代币',
			startingAfterwards: "开始倒计时:",
			endingAfterwards: "结束倒计时:",
			gameEnded: "本轮游戏已结束，请下次再来",
			topup: "充值",
			inActivatedErr: "你的NFT未激活",
			activate: "激活",
		},
		gameChooseModel: {
			points: "积分",
			ranking: "排名",
			itemModel: "道具战",
			competitiveModel: "竞技模式",
			classicModel: "经典模式",
			oneVS1: "随机1V1",
			oneVSN: "多人游戏",
			friendsVS: "好友对战",
			oneVS8: "8人乱斗",
			createRoom: "创建房间",
			roomList: "房间列表",
			inviteFriendsContent: "复制邀请链接，分享给朋友，一起玩吧",
			copy: "复制",
			matching: "正在匹配",
			close: "取消",
			username: "用户名",
			room: "的房间",
			waiting: "等待中",
			spectator: "观战席",
			spectating: "观战",
			exit: "退出房间",
			previous: "上一页",
			next: "下一页",
			quickJoin: "快速加入",
			join: "加入游戏",
			back: "返回",
			refresh: "刷新",
			joinRoomError: "房间已失效，加入房间失败",
			quickJoinError: "当前没有符合条件的房间",
			startGame: "开始游戏",
			locked: "锁定中",
			homeowner: "房主",
			threePlayerBattle: "三人战",
			sixPlayerBattle: "六人战",
			insufficientRemainingTickets: "余票不足",
			invitationLinkInvalid: "邀请链接失效，请确认",
			roomFullJoinFailed: "房间已满，加入失败",
			pleaseCopyInvitationLinkFirst: "请先复制邀请链接",
			generatingLink: "链接生成中",
			adTitle: "看广告，赢得TET奖励！",
			adDownload: "下载",
			adLoadMore: "看广告",
		},
		soundSettings: {
			setting: "设置",
			music: "音乐",
			soundEffects: "音效",
			specialEffects: "特效",
			diaposalReminder: "消块提醒",
			language: "语言",
			keyPosition: "键位",
			off: "关",
			on: "开",
			prompt: "提示",
			save: "保存",
			cancel: "取消",
			left: "左移",
			right: "右移",
			rotateR: "右变形",
			rotateL: "左变形",
			push: "下落",
			momentum: "垂直下落",
			freeze: "冰冻",
			add: "添砖加瓦",
			acc: "加速"
		},
		mint: {
			NFTBlindBox: "NFT盲盒",
			startForging: "铸造",
			forging: "铸造",
			freeGiftBox: "免费礼盒",
			haveReceived: "您已领取",
			claimNow: "立即领取",
			rarity: "稀有度",
			confirm: "确定",
			tryAgain: "再来一次",
			tenMoreTimes: "再来十次",
			mintingNFT: "铸造NFT",
			success: "成功：",
			failed: "失败：",
			minting: "铸造中：",
			refunding: "退款中",
			onclick: "点击",
			passable: "可通过",
			viewTheMintedNFT: "查看铸造好的NFT",
			transferNFT: "转入NFT",
			goToIncomeRecords: "【个人中心-收支明细】",
			goToTransferInNft: "【个人中心-转入NFT】",
			goToMyNft: "【个人中心-我的NFT】",
			checkFailure: "查看失败原因",
			genesisNFT: "创世NFT",
			comingSoon: "即将推出：",
			price: "价格",
			limitedEdition: "%减免，\n限时限量优惠促销",
			link: "立即前往",
			enjoy: "享",
			discount: "折",
			buy: "购买",
			priceInfo1: "最大滑点2%，如果价格于下单到订单确认期间发生变化，这被称为“滑点”",
			priceInfo2: "如果滑点超过“最大滑点”设置，您的铸造将会自动取消。",
			mintingVSYSInfo: "请稍候，然后您可以通过游戏内的【个人中心-转入NFT】查看已铸造的NFT。",
			mintingVSYSSuccess: "铸造NFT成功,您可以通过游戏内的【个人中心-转入NFT】查看已铸造的NFT。",
			mintingVSYSFailed: "NFT铸造失败,如有疑问请联系社区工作人员",
			mintingVSYSRefunding: "铸造NFT失败,资金正在退回中",
			mintingVSYSRefunded: "铸造NFT失败,资金已原路返回钱包地址，请注意查看",
			pleaseConnectWallet: "请先连接钱包",
			insufficientVSYSBalance: "VSYS余额不足",
			originalPrice: "超过3000原价。",
			pcs: "件",
			left: "剩余",
			limited: "限售",
			expiresIn: "到期时间：",
			tradingSetup: "交易设置",
			customize: "自定义百分比",
			slippageLimit: "滑点上限",
			slippageLimitLower: "此值过低（2%），可能会导致兑换失败",
			slippageLimitUpper: "输入的滑点（20%）被认为是很高的，可能会因而得到较差的汇率",
			buyVsys: "购买VSYS",
		},
		ticket: {
			ticket: "门票",
			purchaseSuccessful: "购买成功",
			purchaseUnsuccessful: "购买失败",
			claimedSuccessful: "领取成功",
			haveClaimedNextCome: "您已领取，请下次再来",
			competitiveTickets: "竞技门票",
			buyNow: "立即购买",
			freeTicket: "升级中",
			freeClaim: "免费领取",
			haveClaimed: "您已领取",
			confirmTicket: "确认购买门票",
			confirmTicektContent1: "确定花费",
			confirmTicektContent2: "TET，购买",
			confirmTicektContent3: "张门票？",
		},
		personalCenter: {
			personalCenter: "个人中心",
			survivalMode: "生存模式",
			classicBattle1v1: "经典1v1",
			itemMode1v1: "道具1v1",
			classiceBattleMulti: "经典多人",
			itemModeMulti: "道具多人",
			tokenRecharge: "TET充值",
			transferNFT: "转入NFT",
			tokenWithdrawal: "TET提现",
			withDrawNFT: "提取NFT",
			myNFT: "我的NFT",
			myTickets: "我的门票",
			incomeRecords: "收支明细",
			safeSettings: "安全设置",
			availableToken: "TET余额",
			refresh: "刷新",
			setAvatar: "设为头像",
			removeAvatar: "去除头像",
			loadMore: "加载更多",
			secondaryPassword: "二级密码",
			requiredSetPassword: "在提现以及提取NFT时需要的密码",
			reset: "重置",
			set: "设置",
			setSuccess: "设置成功",
			resetSecondaryPassword: "重置二级密码",
			setSecondaryPassword: "设置二级密码",
			pleaseEnterSixNumerical: "请输入六位数字密码",
			pleaseEnterSecondaryPassword: "请输入二级密码",
			withdrawNFTSuccess: "提取成功",
			secondaryPasswordNone: "请检查二级密码是否输入",
			pleaseEnterValue: "请输入金额",
			withdrawalAll: "全部提现",
			confirmRefundTitle: "是否确认兑换",
			refundSuccess: "兑换成功，您可通过收支明细查看记录",
			refundFailed: "兑换失败",
			marketAction: "市场动态",
			OnChain: "在链上",
			InGame: "未上架",
			InMarket: "已上架",
			All: "全部",
			InActivated: "未激活",
			Valid: "生效中",
			Expired: "已过期",
			tokenInsufficientFunds: "请检查钱包地址是否正确，当前账户Token余额为0",
			tokenInsufficientError: "错误：余额不足",
			select: "选择",
			enterAmount: "其他金额",
			inviteCashback: "邀请返现",
			inviteTitle: "邀请好友！\n推荐人将获得提现减免劵",
			inviteSubtitle: "查看邀请规则",
			inviteRules: "将邀请凭证代码/链接分享给尚未注册Tetris账户的好友。\n\n常规任务：受邀者必须使用指定的邀请代码/邀请链接注册。每邀请5人，邀请人将获得提现减免劵奖励。",
			invitationCode: "邀请凭证代码",
			invitationLink: "邀请凭证链接",
			inviteStep1: "第一步",
			inviteStep1Content: "分享您的邀请链接",
			inviteStep2: "第二步",
			inviteStep2Content: "邀请好友通过您的链接注册",
			inviteStep3: "第三步",
			inviteStep3Content: "每邀请5人\n您将获得提现减免劵",
			overview: "概览",
			invitedTotal: "邀请总人数",
			availableInvited: "待兑换邀请人数",
			cashTotal: "提现减免劵总数",
			availableCash: "可用提现减免劵",
			cashWithdrawCoupon: "现金提现券",
			cashbackVouchersSelected: " 返现劵被选择 ",
			feeWaived: " %手续费被减免",
			noCashbackVoucherSelected: "未选择返现券，费用减免最高可达14%",
			lockedAsset: "已锁定资产: ",
			withdrawFee: "提现手续费",
			threshold: "门槛",
			fee: "手续费",
			cash: "提现劵",
			discount: "折扣",
			obtainCoupons: "获取优惠劵",
			luckyDraw: "幸运大转盘",
			rules: "规则",
			rulesContent: "活动时间: 长期，本规则仅适用于本页面参与活动的用户；\n\n活动期间消耗100TET，有概率可获得最高价值9999TET的代币奖励;\n\n50TET 概率:20.00%\n66TET 概率:20.00%\n88TET 概率:10.00%\n100TET 概率:20.00%\n128TET 概率:20.00%\n888TET 概率:5.00%\n999TET 概率:4.90%\n9999TET 概率:0.10%\n\n您若采取任何不正当手段、违反活动规则或法律法规的行为，平台有权单方面取消您的参与资格、获奖资格等，活动最终解释权归平台所有。",
			activityRules: "活动规则",
			winingRecords: "中奖记录",
			congratulations: "恭喜",
			congratulationsSubtitle: "奖品已放入余额，您可以去查看",
			checkBalance: "查看余额",
			startDraw: "开始抽奖\n(- 100TET)",
			listEnd: "已经到底了 🤐",
			reviewing: "系统审核中，请等待",
		},
		tableData: {
			ordinal: "序号",
			UID: "昵称",
			challenge: "关卡",
			ability: "战力",
			score: "分数",
			highestWinStreak: "最高连胜",
			wins: "胜（次）",
			losses: "负（次）",
			points: "积分",
			highestScore: "单场最高",
			MVPs: "MVP(s)",
			time: "时间",
			amount: "数量",
			type: "类型",
			status: "状态",
			recipient: "接收方",
			expiredAt: "有效期",
			price: "价值",
			recipientType: "获得方式",
			action: "操作",
			permanent: "永久",
			all: "全部",
			free: "领取",
			paid: "购买",
			earned: "奖励",
			valid: "有效",
			expired: "失效",
			lockedInRoom: "已锁定",
			used: "已使用",
			refund: "兑换",
			airdrop: "空投",
			unrefund: "不可兑换",
			refunded: "已退还",
			minting: "铸造中",
			pending: "等待中",
			locked: "锁定中",
			success: "成功",
			failed: "失败",
			allTicket: "门票",
			freeTicket: "免费门票",
			paidTicket: "付费门票",
			buyTicket: "购买门票",
			refundTicket: "退还门票",
			useTicket: "使用门票",
			earnedTicket: "奖励门票",
			depositToken: "充值Token",
			withdrawToken: "提现Token",
			reward: "奖励",
			mintNFT: "铸造NFT",
			freeNFT: "领取免费NFT",
			depositNFT: "转入NFT",
			withdrawNFT: "提现NFT",
			list: "上架NFT",
			remove: "下架NFT",
			sell: "卖出NFT",
			buy: "买入NFT",
			exchangeFee: "兑换手续费20%",
			SpinWheel: "抽奖",
			InvitationReward: "邀请奖励",
			SpinWheeled: "抽中",
			Winners: "中奖公告",
			season: "赛季",
			rank: "排名",
			"Classic 1v1": "经典1v1",
			"Classic Multi": "经典多人",
			"Props 1v1": "道具1v1",
			"Props Multi": "道具多人",
			review: "审核中",
		},
		game: {
			chooseHero: "选择英雄",
			quickSetup: "自动编队",
			initialize: "初始化",
			abilityDemand: "战力需求",
			ability: "战力",
			play: "开始游戏",
			level: "关卡",
			freeLevels: "免费关卡",
			premiumLevels: "高级关卡 (更多奖励)",
			comingSoon: "即将到来",
			enemyTerritory: "敌方区域",
			freezeCountdown: "冰冻倒计时",
			frozenCard: "冰冻卡",
			brickingCard: "添砖加瓦卡",
			accelerationCard: "加速卡",
			spectating: "正在观战",
			itemDropRate: "道具掉落率",
			points: "得分",
			pointss: "分",
			countdownTimer: "倒计时",
			currentState: "当前状态",
			alive: "剩余",
			roomList: "房间列表",
			title: "首页",
			backTitle: "返回首页",
			restart: "重新开始",
			backGame: "继续游戏",
			keepWatching: "继续观战",
			challengeCleared: "挑战成功",
			challengeFailed: "挑战失败",
			competitionCompleted: "胜负已分",
			gamePaused: "游戏暂停",
			gamePausedContent: '点击“继续游戏”继续',
			challengeFailedContent: "很遗憾，你挑战失败",
			soloScoreFail: "本局无效，低于400分不上排行榜，请享受游戏",
			challengeClearedContent1: "恭喜你挑战成功，你将获得每日通关空投奖励，请于",
			challengeClearedContent2: "后查看钱包资产。",
			confirm: "确定",
			ticket: "门票",
			tip1: "第一名",
			tip2: "第二名",
			tip3: "第三名",
			tip4: "第四名",
			tip5: "第五名",
			tip6: "第六名",
			tip7: "第七名",
			tip8: "第八名",
			win: "胜",
			defeat: "败",
			gameinvalid: "对局异常，记录无效。",
			networkDisconnectedGameOver: "您的网络断开，游戏结束",
			networkError: "您的网络异常，请注意网络链接",
			copySuccess: "复制成功，请前往Discord分享",
			isAdTitle: "点击下方领取分数按钮，获取本局奖励，未领取视为放弃本局积分",
			isAdButton: "领取分数",
			isAdedButton: "分数领取成功",
		},
		market: {
			//  market list
			refresh: "刷新",
			results: "总数",
			rarity: "稀有度",
			activateStatus: "激活状态",
			validityPeriod: "有效时间",
			validityPeriodError: "最小值必须小于最大值",
			sell: "我也要卖",
			loadMore: "加载更多",
			"price ASC": "价格从低到高",
			"price DESC": "价格从高到低",
			"quality ASC": "稀有度从低到高",
			"quality DESC": "稀有度从高到低",
			"time ASC": "最早",
			"time DESC": "最近",
			tetrisSeries: "俄罗斯方块系列",
			buy: "买",
			restartPeriod: "修改有效时间",
			// detail
			nftValidityPeriod: "NFT有效期:",
			listingEndsAt: "拍卖结束于：",
			buyNow: "立即购买",
			remove: "下架",
			sellTitle: "设置售价",
			activation: "激活",
			expired: "已过期",
			contract: "合约",
			tokenId: "Token ID",
			blockchain: "区块链",
			token: "Token",
			royalties: "版税",
			info: "详情",
			NFTpowerboost: "战力",
			NFTitemdroprateincrease: "道具掉落率",
			// buy
			price: "价格",
			paymentCurency: "支付货币",
			balance: "余额",
			cancel: "取消",
			insufficientFunds: "余额不足",
			handlingFee: "手续费",
			handlingFeeContent: "免费上架，一旦售出以下费用将从支付里扣除",
			transactionFee: "交易费",
			creatorFee: "版权费",
			list: "出售",
			expiration: "过期时间",
			hour1: "1小时",
			hour3: "3小时",
			hour6: "6小时",
			hour168: "7天",
			hour336: "14天",
			hour720: "30天",
			listButton: "上架",
			confirm: "确定",
			listSuccess: "上架成功",
			listFiled: "上架失败",
			pleaseEnterPrice: "请输入价格",
			buySuccess: "购买成功",
			buyFiled: "购买失败",
			listInBulk: "批量上架",
			activate: "激活",
			activated: "已激活",
			transferOut: "去转入",
			remove: "下架",
			extract: "提取",
			listExpiration: "上架过期时间",
			productAtPrice: "将商品定价为：",
			willEar: "卖出后赚取：",
			activateNftTitle: "确认是否激活",
			activateNftContent1: "激活后此NFT寿命为60天，",
			activateNftContent2: "激活操作不可逆，请谨慎操作",
			activateNftSuccess: "激活成功",
			activateNftFailed: "激活失败",
			activateNftFailedText: "NFT已上架，无法激活",
			removeNftTitle: "确认是否下架",
			removeNftSuccess: "下架成功",
			removeNftFiled: "下架失败",
		}
	}
}