import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Button, Carousel, Dropdown, Image } from 'antd';
import Slider from "react-slick";

import Header from '../Components/Home/Header';
import BlockMovement from '../Components/SpecialEffects/BlockMovement/Index';
import TetrisBlockMovement from '../Components/SpecialEffects/TetrisBlockMovement/Index';
import TwinklingStars from '../Components/SpecialEffects/TwinklingStars/Index';
import ConnectWalletDialog from '../Components/PublicComponents/ConnectWalletDialog';
import MintNFTMessage from '../Components/PublicComponents/MintNFTMessage'
import PublicPopup from '../Components/PublicComponents/PublicPopup'
import FlipClock from '../Components/FlipClock/FlipClock';
import BuyVsys from '../Components/PublicComponents/buyVsys';
import EventPromotionPopup from '../Components/Home/EventPromotionPopup';

import './OfficialSite.scss'
import logo from "../assets/icon/logo.png";
import stroyClassic from "../assets/icon/stroy_classic.png"
import stroyCompetitive from "../assets/icon/stroy_competitive.png"
import stroyItem from "../assets/icon/stroy_item.png"

import soloModeImg from '../assets/icon/solo_mode_bg.png'
import itemModeImg from '../assets/icon/item_mode_bg.png'
import competitiveModeImg from '../assets/icon/competitive_mode_bg.png'
import classicModeImg from '../assets/icon/classic_mode_bg.png'

import gifIcon from "../assets/hall/gif_icon.png"
import HuobiLogo from '../assets/buyVSYS/kucoin.svg';
import huobiActiveImg1 from "../assets/web/huobi_active_img1.png"
import huobiActiveImg2 from "../assets/web/huobi_active_img2.png"
import inviteEarnImg from '../assets/web/invite_earn.png'

import nftLoong from "../assets/nft/loong.png"
import nftWolf from "../assets/nft/wolf.png"
import nftWukong from "../assets/nft/wukong.png"
import nftZeus from "../assets/nft/zeus.png"

import nftPic1 from "../assets/icon/nft1.png";
import nftPic2 from "../assets/icon/nft2.png";
import nftPic3 from "../assets/icon/nft3.png";
import nftPic4 from "../assets/icon/nft4.png";

import leftBtn from "../assets/public_icon/left_icon.svg";
import rightBtn from "../assets/public_icon/right_icon.svg";
import AddIcon from '../assets/public_icon/add_icon.svg'
import RemoveIcon from '../assets/public_icon/remove_icon.svg'
import settingIcon from "../assets/hall/ic_settings.png"
import closeIcon from '../assets/public_icon/close_icon.svg'

import twitter from "../assets/icon/twitter.png";
import fb from "../assets/icon/fb.png";
import discord from '../assets/icon/discord.svg'
import telegram from "../assets/icon/telegram.svg"

import avatarDefault from "../assets/hall/avatar_default.png"

import { getVSYS2USD, postGuestNftMint, postNftDiscountInfo } from '../api';
import { getMessage, getVSYSAmount, send, signContent } from '../tools/wallet';
import { openNotification } from '../tools/notification';
import { MINTPRICE, IMAGE_BASEURL } from '../tools/gameConfig'
import { AddVSYSTransaction } from '../redux/actions/user/userActions';
import { viewCode } from '../tools/viewCode';

const settings = {
    centerMode: true,
    easing: 'ease',
    //  fade: true,
    // infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    // speed: 500,
    arrows: false
};

let time = null
const OfficialSite = (props) => {

    const location = useLocation()
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const carouselEL = useRef(null)
    const carouselELNFT = useRef(null)
    const query = useQuery()
    const homePage = query.get("homePage")

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [currentModeIndex, setCurrentModeIndex] = useState(0)
    const [currentNftIndex, setCurrentNftIndex] = useState(0)
    const [modeSliderTitle, setModeSliderTitle] = useState(t("hall.survivalMode"))
    const [nftSliderTitle, setNftSliderTitle] = useState("dok")
    const [selectedPage, setSelectedPage] = useState(homePage || "casting")
    const [footerHover, setFooterHover] = useState(false)

    const [connectWalletLoading, setConnectWalletLoading] = useState(false)
    const [downloadWallet, setDownloadWallet] = useState(false)
    const [address, setAddress] = useState("")
    const [vsysAddress, setVsysAddress] = useState("")
    const [briefAddr, setBriefAddr] = useState("")

    const [price, setPrice] = useState(0)
    const [count, setCount] = useState(1)
    const [total, setTotal] = useState(0)
    const [discountTotal, setDiscountTotal] = useState(0)
    const [buyVsys, setBuyVsys] = useState(false)

    const [discount, setDiscount] = useState(0)
    const [discountCopy, setDiscountCopy] = useState(0)
    const [discountEndingTime, setDiscountEndingTime] = useState("")
    const [discountStartingTime, setDiscountStartingTime] = useState("")
    const [discountEnd, setDiscountEnd] = useState(false)
    const [nftMinted, setNftMinted] = useState(0)

    const [tradingSetting, setTradingSetting] = useState(false)
    const [slippage, setSlippage] = useState(2)
    const [slippageSave, setSlippageSave] = useState(2)
    const [slippageErr, setSlippageErr] = useState(false)

    const [buyLoading, setBuyLoading] = useState(false)
    const [showHuobi, setShowHuobi] = useState(true)

    const carouselContent = [
        { src: soloModeImg, title: t("hall.survivalMode"), type: 1 },
        { src: itemModeImg, title: t("hall.itemBattle"), type: 2 },
        { src: competitiveModeImg, title: t("hall.multiplayerCompetition"), type: 3 },
        { src: classicModeImg, title: t("hall.classicMode"), type: 4 },
    ]

    const nftCarouselContent = [
        { src: nftLoong, title: "loong" },
        { src: nftWolf, title: "wolf" },
        { src: nftWukong, title: "wukong" },
        { src: nftZeus, title: "zeus" },
    ]

    useEffect(() => {
        if (location.state) {
            navigate('/webLogin', { state: location.state })
        }
    }, [location.state])

    const onChange = (from, to) => {
        setCurrentModeIndex(to)
        setModeSliderTitle(carouselContent[to]?.title)
    };

    const onChangeNFT = (from, to) => {
        setCurrentNftIndex(to)
        setNftSliderTitle(nftCarouselContent[to]?.title)
    };

    const nextPage = (type) => {
        if (type === 'nft') {
            carouselELNFT.current.slickNext()
        } else {
            carouselEL.current.slickNext()
        }
    }

    const prevPage = (type) => {
        if (type === 'nft') {
            carouselELNFT.current.slickPrev()
        } else {
            carouselEL.current.slickPrev()
        }
    }

    const selectPage = (page) => {
        setSelectedPage(page)
    }

    const connectWallet = async () => {
        setConnectWalletLoading(true)
        const res = await getMessage()
        setConnectWalletLoading(false)
        if (!res.result) {
            const { type, version } = res
            switch (type) {
                case "download":
                    setDownloadWallet(true)
                    break;
                case "version":
                    openNotification(t("wallet.versionError"))
                    break;
                case "success":
                    openNotification(t("wallet.authorizationError"))
                    break;
                case "switchNetwork":
                    openNotification(t("wallet.networkSettingError"))
                    break;
                case "login":
                    openNotification(t("wallet.walletLoginError"))
                    break;
                default:
                    break;
            }
        }
        // return address
        else {
            if (res.result) {
                setAddress(res.msg)
                setVsysAddress(res.msg)
            }
        }
    }

    useEffect(() => {
        if (vsysAddress) {
            window?.vsys?.on('accountsChanged', handleAccountsChanged);
        }
        return () => {
            window?.vsys?.removeListener('accountsChanged', handleAccountsChanged);
        }
    }, [vsysAddress])

    useEffect(() => {
        if (address) {
            let addr = address
            let addrChars = addr.split("");
            addrChars.splice(7, 20, "...");
            setBriefAddr(addrChars.join(""))
        } else {
            setBriefAddr("")
        }
    }, [address])

    const handleAccountsChanged = (accounts) => {
        if (vsysAddress === accounts[0]) {
            setAddress(vsysAddress)
        } else {
            setAddress('')
        }
    }

    const onClose = () => {
        setDownloadWallet(false)
    }

    const closeAddress = () => {
        setAddress("")
        setVsysAddress("")
    }

    useEffect(() => {
        if (selectedPage === "casting") {
            if (window.location.search) {
                navigate("/home", { replace: true })
            }
            if (price > 0) {
                clearInterval(time)
                time = setInterval(() => {
                    getPrice()
                }, 10000);
            } else {
                getPrice()
                clearInterval(time)
                time = setInterval(() => {
                    getPrice()
                }, 3000);
            }

        } else {
            if (selectedPage) {
                setSelectedPage(selectedPage)
                if (window.location.search) {
                    navigate("/home", { replace: true })
                }
            }
            clearInterval(time)
        }
        return () => {
            clearInterval(time)
        }
    }, [price, selectedPage])


    useEffect(() => {
        getDiscountInfo()
    }, [])

    const getDiscountInfo = () => {
        postNftDiscountInfo().then((res) => {
            if (res.data.code) { } else {
                setDiscountCopy(res.data.data?.discountRatio)
                setNftMinted(res.data.data?.nftMinted)
                if (new Date(res.data.data?.discountStartingTime).getTime() < new Date().getTime()) {
                    setDiscountEndingTime(res.data.data?.discountEndingTime)
                    setDiscountStartingTime(res.data.data?.discountStartingTime)
                    if (new Date(res.data.data?.discountEndingTime).getTime() > new Date().getTime()) {
                        setDiscount(res.data.data?.discountRatio)
                    } else {
                        setDiscount(0)
                    }
                } else {
                    setDiscountStartingTime(res.data.data?.discountStartingTime)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getPrice = async () => {
        try {
            let VSYS2USD = ((await getVSYS2USD()).data?.data?.vsysExchange?.usd || 0)
            if (VSYS2USD > 0) { setPrice((MINTPRICE * 1 / VSYS2USD)) }
            else { setPrice(0) }
        } catch (error) {
            console.log(error)
            setPrice(0)
        }
    }

    // update count
    const updateCount = (type) => {
        if (type === "+") {
            if (count >= 10) {
                setCount(10)
            } else {
                setCount(p => p + 1)
            }
        } else if (type === "-") {
            if (count > 2) {
                setCount(p => p - 1)
            } else {
                setCount(1)
            }
        }
    }

    useEffect(() => {
        if (price) {
            setTotal(Math.ceil(price * count * (100 + slippage) / 100))
            setDiscountTotal(Math.ceil(price * count * (1 - discount) * (100 + slippage) / 100))
        } else {
            setTotal(0)
            setDiscountTotal(0)
        }
        return () => {
        }
    }, [count, price, slippage, discount])

    const buyNFT = async () => {
        if (!discount && !discountEnd) { return }
        let number = count / 1
        if (!address) { return openNotification(t("mint.pleaseConnectWallet")) }
        if (discountTotal <= 0) { return }
        setBuyLoading(true)
        let amount = await getVSYSAmount()
        if ((amount?.amount || 0) / 1 + 0.1 < discountTotal) {
            setBuyLoading(false)
            return openNotification(t("mint.insufficientVSYSBalance"))
        }
        let res = await send(discountTotal)
        if (res.result) {
            window._czc && window._czc.push(["_trackEvent", "铸造NFT页", "购买NFT"]);
            signContent("You are using VSYS Address " + address + " to mint " + number + " NFT with " + discountTotal + " VSYS by transaction " + res.transactionId + ". Please sign the transaction to confirm.").then((signRes) => {
                postGuestNftMint({
                    mintNFTNumber: number,
                    totalVsysCost: discountTotal / 1,
                    vsysAddress: address,
                    vsysPubKey: signRes.pubKey,
                    vsysPaymentTxId: res.transactionId,
                    signedMessage: signRes.signature
                }).then((res) => {
                    if (!res.data.code) {
                        if (res.data.data.guestNFTMintingIds) {
                            setBuyLoading(false)
                            openNotification(t("responseCode.success"))
                            window._czc && window._czc.push(["_trackEvent", "铸造NFT页", "购买NFT"]);
                            props.AddVSYSTransaction({ key: new Date().getTime(), transaction: res.data.data.guestNFTMintingIds, type: "pending" })
                        }
                    } else {
                        if (res.data.code === 3015) props.AddVSYSTransaction({ key: new Date().getTime(), transaction: res.data.data.guestNFTMintingIds, type: "refunding" })
                        setBuyLoading(false)
                        openNotification(t(`responseCode.${viewCode(res.data.code) || "otherCode"}`))
                    }
                }).catch((err) => {
                    console.log(err)
                    setBuyLoading(false)
                    openNotification(t("responseCode.networkFailed"))
                })
            })
        } else {
            setBuyLoading(false)
        }
        openNotification(res.result ? t("wallet.walletSuccess") : res.message)
    }

    useEffect(() => {
        if (footerHover) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;
        }
    }, [footerHover])

    useEffect(() => {
        if (slippageSave < 2) {
            setSlippageErr("lower")
        } else if (slippageSave > 20) {
            setSlippageErr("upper")
        } else {
            setSlippageErr(false)
        }
    }, [slippageSave, tradingSetting])

    const tradingSaveChange = (val) => {
        if (val > 0) {
            setSlippageSave(val)
        } else {
            setSlippageSave("")
        }
    }

    const tradingSaveFun = () => {
        setSlippage(slippageSave)
        setTradingSetting(false)
        setSlippageErr(false)
    }

    const tradingCloseFun = () => {
        setTradingSetting(false)
        setSlippageSave(slippage)
        setSlippageErr(false)
    }

    const gotoMint = () => {
        setSelectedPage("casting")
    }

    return (
        <div className="offical_site">
            <Header selectPage={selectPage} connectWalletLoading={connectWalletLoading} address={briefAddr} connectWallet={connectWallet} setAddress={closeAddress} avatarDefault={avatarDefault} />
            {downloadWallet ? <ConnectWalletDialog onClose={onClose} /> : ""}
            <EventPromotionPopup
                discountCopy={discountCopy}
                discountStartingTime={discountStartingTime}
                getDiscountInfo={getDiscountInfo}
                discountEndingTime={discountEndingTime}
                setDiscountEnd={setDiscountEnd}
                discountEnd={discountEnd}
                gotoMint={gotoMint}
            />
            {tradingSetting ?
                <PublicPopup isOverlay
                    title={<Dropdown
                        dropdownRender={(menu) => (
                            <div style={{ background: "rgba(25, 22, 41, 0.95)", boxShadow: "3px 3px 3px 0px rgba(73, 75, 134, 0.30)", borderRadius: "1vw", width: "15vw", height: "auto", color: "#fff", fontFamily: "Noto Sans", padding: "1.5vw", boxSizing: "border-box", fontSize: "0.78vw" }}>
                                {t("mint.priceInfo1")}
                                <br />
                                <br />
                                {t("mint.priceInfo2")}
                            </div>
                        )}
                        trigger={['click', 'hover']}
                        placement='bottom'
                    >
                        <div style={{ display: "flex", gap: "0.5vw", alignItems: "center", justifyContent: "center" }}>{t("mint.tradingSetup")}<div className="price_info_ic"></div></div>
                    </Dropdown>}

                    closefun={tradingCloseFun} >
                    <div className="trading">
                        <div className='trading_point'>
                            <div className='trading_item'>
                                <div className='trading_item_point'>
                                    <span className='trading_item_title'>{t("mint.slippageLimit")}</span>
                                    <span>2%</span>
                                </div>
                                {slippageSave === 2 ? <div className='selected_button'></div> : <div className='unselected_button' onClick={() => { setSlippageSave(2) }}></div>}
                            </div>
                            <div className='trading_item'>
                                <div className='trading_item_point'>
                                    <span className='trading_item_title'>{t("mint.slippageLimit")}</span>
                                    <span>3%</span>
                                </div>
                                {slippageSave === 3 ? <div className='selected_button'></div> : <div className='unselected_button' onClick={() => { setSlippageSave(3) }}></div>}
                            </div>
                            <div className='trading_item'>
                                <div className='trading_item_point'>
                                    <span className='trading_item_title'>{t("mint.customize")}</span>
                                    <input className='trading_input' onChange={(e) => tradingSaveChange(Number(e.target.value))} value={slippageSave} type="tel" />
                                </div>
                                {slippageSave !== 2 && slippageSave !== 3 ? <div className='selected_button'></div> : <div className='unselected_button' onClick={() => { setSlippageSave("") }}></div>}
                            </div>
                        </div>
                        <div className='trading_button'>
                            <Button className='button_common primary_button' onClick={tradingSaveFun}>{t("soundSettings.save")}</Button>
                        </div>
                        {slippageErr === "lower" ?
                            <div className='trading_warning'>
                                <div className='warning_icon'></div>
                                {t("mint.slippageLimitLower")}
                            </div>
                            : slippageErr === "upper" ?
                                <div className='trading_warning'>
                                    <div className='warning_icon'></div>
                                    {t("mint.slippageLimitUpper")}
                                </div>
                                : ""}
                    </div>
                </PublicPopup> : ""}
            {buyVsys ? <BuyVsys closefun={() => { setBuyVsys(false) }} /> : ""}
            <>
                {/* <div className='huobi_active'>
                    <div className='huobi_active_div'>
                        <div className='active_button' onClick={() => { setShowHuobi(!showHuobi) }}>
                            <Image preview={false} src={showHuobi ? RemoveIcon : AddIcon} />
                        </div>
                        <div className='active_content'>
                            <Image className='active_content1_img' preview={false} src={huobiActiveImg1} />
                            <div>{t("home.huobiExchange")}</div>
                        </div>
                        {showHuobi ?
                            <div className='active_content'>
                                <Image className='active_huobi_img' preview={false} src={HuobiLogo} />
                                <div>{t("home.huobiExchange")}</div>
                                <div className='active_time'>{t("home.huobiActiveTime")}</div>
                                <Image className='active_content2_img' preview={false} src={huobiActiveImg2} />
                                <div className='active_content_content'>{t("home.huobiActiveContent")}</div>
                                <Button className='button_common button_shadow primary_button' onClick={() => { window.open("https://www.kucoin.com/announcement/en-vsys-trading-campaign-trade-and-share-$10,000-USDT-20240628", "_blank") }} >{t("mint.link")}</Button>
                            </div> : ""}

                    </div>
                </div> */}
                <div className='huobi_active'>
                    <div className='huobi_active_div'>
                        {showHuobi ?
                            <div className='active_content'>
                                <div className='active_button' onClick={() => { setShowHuobi(!showHuobi) }}>
                                    <Image preview={false} src={closeIcon} />
                                </div>
                                <div className='active_content_content'>{t("home.inviteYourFriends")}</div>
                                <div
                                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                >
                                    <Image className='invite_earn_img' preview={false} src={inviteEarnImg} />
                                </div>
                                <Button className='button_common button_shadow primary_button' component={Link} href='/invite' >{t("home.invite")}</Button>
                            </div> : ""}
                    </div>
                </div>
                {selectedPage === "casting" ?
                    <div className='dc_active'>
                        <div className='dc_active_div' onClick={() => { window.open("https://discord.gg/TrnXN5kaEy", "_blank") }}>
                            <div className='active_content'>
                                <Image
                                    preview={false}
                                    src={discord}
                                    className='dc_img'
                                />
                                <div>{t("home.moreActive")}</div>
                                <div className="invite">
                                    <Image className='gif_icon' preview={false} src={gifIcon} />
                                </div>
                            </div>
                        </div>
                    </div> : ""}
            </>
            {selectedPage === "Home" ?
                <>
                    <div className="panel">
                        <BlockMovement />
                        <TwinklingStars />
                        <div className="logo">
                            <div className="web_title">{t('home.earn')}</div>
                            <Image
                                preview={false}
                                width={"100%"}
                                src={logo}
                                className='logo_img'
                            />
                        </div>
                        <Button className="button_common primary_button" onClick={() => navigate('/webLogin')}>
                            {t('commonText.start')}
                        </Button>
                    </div>
                    <div className="stroy" id='Story'>
                        <TetrisBlockMovement />
                        <div className="left_side">
                            <div className="inner_left">
                                <div className="mode">
                                    <Image
                                        preview={false}
                                        src={stroyClassic}
                                        className='mode_img'
                                    />
                                </div>
                                <div className="mode">
                                    <Image
                                        preview={false}
                                        src={stroyItem}
                                        className='mode_img'
                                    />
                                </div>
                            </div>
                            <div className="inner_right">
                                <div className="mode">
                                    <Image
                                        preview={false}
                                        src={stroyCompetitive}
                                        className='mode_img'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="right_side running_light">
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className="title">
                                {t("home.storyTitle")}
                            </div>
                            <div className="content">
                                <div className="p">
                                    {t("home.stroyContent")}
                                </div>
                            </div>
                            <div className="start">
                                <Button className="button_common primary_button" onClick={() => navigate('/webLogin')}>
                                    {t('commonText.start')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="mode" id='Model'>
                        <TetrisBlockMovement />
                        <div className="left_side running_light">
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className="title">
                                {t("home.modeTitle")}
                            </div>
                            <div className="content">
                                <div className="p">
                                    {t("home.modeContent")}
                                </div>
                            </div>
                            <div className="start">
                                <Button className="button_common primary_button" onClick={() => navigate('/webLogin')}>
                                    {t('commonText.start')}
                                </Button>
                            </div>
                        </div>
                        <div className="right_side">
                            <div className="carousel">
                                <Slider ref={carouselEL} beforeChange={onChange} {...settings} className='coustom_style'>
                                    {
                                        carouselContent.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={currentModeIndex === index ? "inner inner_active" : "inner"}>
                                                        <Image
                                                            preview={false}
                                                            src={item.src}
                                                            className='img_1'
                                                        />
                                                        <div className={"mode_title mode" + item.type}>
                                                            {new Array(item.type).fill(false).map((item, index) => {
                                                                return <div key={index}></div>
                                                            })}
                                                            {/* {item.title} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>

                            <div className="btn_group">
                                <Button onClick={() => prevPage()} className='btn arrow_button'>
                                    <Image preview={false} src={leftBtn} />
                                </Button>
                                <div className="text">{modeSliderTitle}</div>
                                <Button onClick={() => nextPage()} className='btn arrow_button'>
                                    <Image preview={false} src={rightBtn} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="nft" id='Nft'>
                        <TetrisBlockMovement />
                        <div className="left_side">
                            <div className="carousel">
                                <Slider ref={carouselELNFT} beforeChange={onChangeNFT} {...settings} className='coustom_style'>
                                    {
                                        nftCarouselContent.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={currentNftIndex === index ? "inner inner_active" : "inner"}>
                                                        <Image
                                                            preview={false}
                                                            src={item.src}
                                                            className='img_1'
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>

                            <div className="btn_group">
                                <Button onClick={() => prevPage("nft")} className='btn arrow_button'>
                                    <Image preview={false} src={leftBtn} />
                                </Button>
                                <div className="text">{nftSliderTitle}</div>
                                <Button onClick={() => nextPage("nft")} className='btn arrow_button'>
                                    <Image preview={false} src={rightBtn} />
                                </Button>
                            </div>
                        </div>
                        <div className="right_side running_light">
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className='run'></div>
                            <div className="title">
                                {t("home.nftTitle")}
                            </div>
                            <div className="content">
                                <div className="p">
                                    {t('home.nftContent')}
                                </div>
                            </div>
                            <div className="start">
                                <Button className="button_common primary_button" onClick={() => navigate('/webLogin')}>
                                    {t('commonText.start')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="route">
                        <div className="route_bg"></div>
                        <TetrisBlockMovement />
                        <div className="popup">
                            {t("home.overview")}
                        </div>
                        <div className="popup position_1">
                            {t("home.verification")}
                        </div>
                        <div className="popup pic_position">
                            <Image
                                preview={false}
                                src={nftPic1}
                                className='nft_pic'
                            />
                        </div>
                        <div className="popup position_2">
                            {t("home.developing")}
                        </div>
                        <div className="popup pic_position position_3">
                            <Image
                                preview={false}
                                src={nftPic2}
                                className='nft_pic'
                            />
                        </div>
                        <div className="popup position_4">
                            {t("home.tokens")}
                        </div>
                        <div className="popup position_5">
                            {t("home.home")}
                        </div>
                        <div className="popup position_6">
                            {t("home.community")}
                        </div>
                        <div className="popup position_7">
                            ICO
                        </div>
                        <div className="popup position_8">
                            {t("home.pawn")}
                        </div>
                        <div className="popup position_9">
                            {t("home.airdrop")}
                        </div>
                        <div className="popup position_10">
                            {t("home.event")}
                        </div>
                        <div className="popup pic_position position_11">
                            <Image
                                preview={false}
                                src={nftPic3}
                                className='nft_pic'
                            />
                        </div>

                        <div className="popup pic_position position_12">
                            <Image
                                preview={false}
                                src={nftPic4}
                                className='nft_pic'
                            />
                        </div>
                        <div className="popup position_13">
                            {t("home.appStore")}
                        </div>

                        <div className="popup position_14">
                            {t("home.moreEvents")}
                        </div>

                        <div className="popup position_15">
                            {t("home.more")}
                        </div>

                        <div className="popup position_16">
                            {t("home.marketing")}
                        </div>
                    </div>
                </> :
                <div className={footerHover ? "casting casting_hover" : "casting"} id='casting'>
                    <TetrisBlockMovement />
                    <MintNFTMessage type={"VSYS"} failed={() => { }} success={() => { }} myNft={() => { }} />
                    <div className="web_title">{t("mint.mintingNFT")}</div>
                    {!discountEnd ?
                        discountEndingTime ?
                            <div className="casting_subtitle">
                                <div className='casting_discount_img'></div>
                                {t("mint.expiresIn")}
                                <FlipClock endTime={new Date(discountEndingTime).getTime()} ended={() => { setDiscountEnd(true) }} />
                            </div>
                            : discountStartingTime ?
                                <div className="casting_subtitle">
                                    <div className='casting_discount_img'></div>
                                    {t("mint.genesisNFT")} <br /> {t("mint.comingSoon")}
                                    <FlipClock endTime={new Date(discountStartingTime).getTime()} ended={() => { getDiscountInfo() }} />
                                </div> : ""
                        : ""
                    }
                    <div className="casting_content">
                        <div className='banner'>
                            <Carousel autoplay>
                                <div><Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}Qmeh3qHqcrvP3SVNWRZRNFMcapssxECMWdfR8C7wBqXtDw.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}Qmeh3qHqcrvP3SVNWRZRNFMcapssxECMWdfR8C7wBqXtDw.png?imageView2/1/w/50/h/50`} />} /></div>
                                <div><Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmUHPkeR54vZXmtG7Yhc1THqbjH9E5wMVkZvCkc7t5V6N1.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmUHPkeR54vZXmtG7Yhc1THqbjH9E5wMVkZvCkc7t5V6N1.png?imageView2/1/w/50/h/50`} />} /></div>
                                <div><Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmckrdKVfxpk1F1uZH6d6KfMTRYGCJQPKvRxXktUs2i88h.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmckrdKVfxpk1F1uZH6d6KfMTRYGCJQPKvRxXktUs2i88h.png?imageView2/1/w/50/h/50`} />} /></div>
                                <div><Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmUkUk7U3foajz9rQEapmQi4SrUxiLrBFZytd9MoNAFy6J.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmUkUk7U3foajz9rQEapmQi4SrUxiLrBFZytd9MoNAFy6J.png?imageView2/1/w/50/h/50`} />} /></div>
                                <div><Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmcSjQnsnzECzUmS2uGDxo3N9WnsCZYHuSPtiqfs6VNpfh.png?imageView2/1/w/500/h/500`} placeholder={<Image width={"100%"} preview={false} fallback={avatarDefault} src={`${IMAGE_BASEURL}QmcSjQnsnzECzUmS2uGDxo3N9WnsCZYHuSPtiqfs6VNpfh.png?imageView2/1/w/50/h/50`} />} /></div>
                            </Carousel>
                        </div>
                        <div className="casting_price">
                            <div className='price'>
                                <div className="casting_price_title">{t("mint.price")}</div>
                                {discountEnd || !discount ? "" :
                                    <>
                                        <div className='limited'>
                                            <div className='limited_title'>{t("mint.limited")}</div>
                                            <div className='limited_subtitle'>3000 {t("mint.pcs")}</div>
                                        </div>
                                        <div className='limit'>
                                            {3000 - nftMinted + " " + t("mint.pcs") + t("mint.left")}
                                        </div>
                                    </>}
                            </div>
                            <div className="casting_price_price">
                                <div className="regular_price">{(discountTotal ? discountTotal : "...") + " VSYS"}</div>
                                <div className="discount_price">{(total ? total : "...") + " VSYS"}</div>
                            </div>
                            <div className="casting_price_ammout">
                                <Button className='arrow_button' onClick={() => { updateCount("-") }}>
                                    <Image preview={false} src={RemoveIcon} />
                                </Button>
                                <div className='count'>{count}</div>
                                <Button className='arrow_button' onClick={() => { updateCount("+") }}>
                                    <Image preview={false} src={AddIcon} />
                                </Button>
                            </div>
                            <div className="casting_action_button">
                                <Button disabled={discountTotal <= 0} loading={buyLoading} onClick={buyNFT} className={discount || discountEnd ? "button_common button_shadow primary_button" : "button_common button_shadow disabled_button"}>{t("mint.buy")}</Button>
                                <div className='buy_vsys' onClick={() => { setBuyVsys(true) }}>{t("mint.buyVsys")}</div>
                            </div>
                            <div className='casting_settings' onClick={() => { setTradingSetting(true) }}>
                                <Image src={settingIcon} className='setting_icon' preview={false} />
                                <span>{t("soundSettings.setting")}</span>
                            </div>
                            {discountEnd || !discount ? "" :
                                <div className='off20'>
                                    {discount * 100}% OFF
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className={footerHover ? "footer_ow footer_ow_hover" : "footer_ow"} onMouseLeave={() => { setFooterHover(false) }} onMouseEnter={() => { setFooterHover(true) }}>
                <div className="container">
                    <div className="left">
                        <div className="slogan_des">{t("home.fontContent")}</div>
                        <div className="reserved">{t("home.reserved")} <span onClick={() => { window.open("https://qiniu.stylika.online/Tetriverse%E2%80%93Terms-Conditions.pdf", "_blank") }} className='reserved_text'>{t("home.termsAndConditions")}</span></div>
                    </div>
                    <div className="right">
                        <div className="join">{t("home.socialMediaLinks")}</div>
                        <div className="social">
                            <Image
                                preview={false}
                                src={twitter}
                                className=' btn tw'
                                onClick={() => window.open('https://twitter.com/tetriverseNFT', "_blank")}
                            />
                            <Image
                                preview={false}
                                src={fb}
                                onClick={() => { window.open("https://www.facebook.com/people/Tetriverse-NFT-Game/61555663441495/", "_blank") }}
                                className=' btn tw'
                            />
                            <Image
                                preview={false}
                                src={discord}
                                onClick={() => { window.open("https://discord.gg/TrnXN5kaEy", "_blank") }}
                                className=' btn fb'
                            />
                            <Image
                                preview={false}
                                src={telegram}
                                onClick={() => { if (t("commonText.language") === "en") { window.open("https://t.me/tetriverseNFT", "_blank") } else { window.open("https://t.me/tetriversechinese", "_blank") } }}
                                className=' btn fb'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = { AddVSYSTransaction };

export default connect(mapStateToProps, mapDispatchToProps)(OfficialSite);